<template>
  <ListLayout :title="$t('orders.pageTitle')"
              :index-request="indexRequest"
              detail-to="orders.show"
              :crud-headers="crudHeaders"/>
</template>

<script>
import { clientIndex } from '@/modules/orders/api';
import ListLayout from '@/modules/orders/components/ListLayout.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'OrdersList',
  components: { ListLayout },
  computed: {
    ...mapGetters('settings', { clientId: 'getCurrentClientId' }),
    crudHeaders() {
      return [
        {
          value: 'statusIcon',
          text: '',
          width: 48,
        },
        {
          value: 'orderStatusId',
          language: 'orderStatusId',
        },
        {
          value: 'storeName',
          language: 'storeName',
        },
        {
          value: 'dealerCodeExt',
          language: 'dealerCodeExt'
        },
        {
          value: 'userName',
          language: 'userName',
        },
        {
          value: 'countryName',
          language: 'countryName',
        },
        {
          value: 'date',
          language: 'date',
        },
      ];
    },
  },
  methods: {
    indexRequest(...args) {
      return clientIndex(this.clientId, ...args);
    },
  },
};
</script>
