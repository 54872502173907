<template>
  <KPaginatedAutocomplete v-bind="$attrs" v-on="$listeners" :paginator="autocomplete"/>
</template>

<script>
import { User } from '@/application/models/User.js';
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete.vue';
import { autocomplete } from '@/modules/user/api';

export default {
  name: 'UsersAutocomplete',
  components: { KPaginatedAutocomplete },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    async autocomplete(...args) {
      const response = await autocomplete(this.clientId, ...args);
      response.data.data = response.data.data.map((user) => new User().mapResponse(user));
      return response;
    },
  },
};
</script>
