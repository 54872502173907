<template>
  <svg width="24" viewBox="0 0 24 24" :fill="backgroundFill" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19 24H17V22.9C16.362 22.77 15.768 22.519 15.243 22.171L14.464 22.95L13.05 21.536L13.829 20.757C13.481 20.232 13.23 19.638 13.1 19H12V17H13.1C13.23 16.362 13.481 15.768 13.829 15.243L13.05 14.464L14.464 13.05L15.243 13.829C15.768 13.481 16.362 13.23 17 13.1V12H19V13.1C19.638 13.23 20.232 13.481 20.757 13.829L21.536 13.05L22.95 14.464L22.171 15.243C22.519 15.768 22.77 16.362 22.9 17H24V19H22.9C22.77 19.638 22.519 20.232 22.171 20.757L22.95 21.536L21.536 22.95L20.757 22.171C20.232 22.519 19.638 22.77 19 22.9V24ZM10.252 20H1C0.448 20 0 19.552 0 19V8H20V10.252C19.361 10.088 18.69 10 18 10C13.585 10 10 13.585 10 18C10 18.69 10.088 19.36 10.252 20ZM18 16C19.104 16 20 16.896 20 18C20 19.104 19.104 20 18 20C16.896 20 16 19.104 16 18C16 16.896 16.896 16 18 16ZM9 6H0L4.5 0H9V6ZM20 6H11V0H15.5L20 6Z"
          :fill="fill"/>
  </svg>
</template>

<script>
export default {
  name: 'IconInProduction',
  props: {
    backgroundFill: {
      type: String,
      default: 'transparent',
    },
    fill: {
      type: String,
      default: '#333333',
    },
  },
};
</script>
