<template>
  <k-field-group language-prefix="orders.filterFields">
    <k-sidebar v-bind="$attrs"
               v-on="$listeners"
               :save-button-text="$t('actions.applyFilters')"
               :title="$t('orders.filterTitle')"
               fixed
               right
               temporary
               width="300"
               @click:confirm="handleFilterClick">
      <KCheckboxGroup v-model="form.orderStatuses"
                      :items="selectableOrderStatus"
                      :persistent-hint="false"
                      dense
                      field="status"
                      hide-details
                      multiple
                      class="mb-2 pb-1"/>
      <StoreAutocomplete :return-object="false"
                         v-model="form.storeId"
                         field="store"
                         hide-details
                         item-text="name"
                         item-value="id"
                         class="mb-2 pb-1"/>
      <UsersAutocomplete :return-object="false"
                         :client-id="clientId"
                         v-model="form.userId"
                         field="user"
                         hide-details
                         item-text="name"
                         item-value="id"
                         class="mb-2 pb-1" />
      <CountryAutocomplete :return-object="false"
                           v-model="form.countryId"
                           field="country"
                           hide-details
                           class="mb-2 pb-1"/>
      <KDateField v-model="form.createdFrom" field="dateFrom" grid="col-sm-12 mb-2 pb-1" hide-details/>
      <KDateField v-model="form.createdTo" field="dateTo" grid="col-sm-12 mb-2 pb-1" hide-details/>
    </k-sidebar>
  </k-field-group>
</template>

<script>
import orderStatus from '@/application/enums/orderStatus.js';
import KCheckboxGroup from '@/components/crud/fields/KCheckboxGroup.vue';
import KDateField from '@/components/crud/fields/KDateField.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KSidebar from '@/components/layout/KSidebar.vue';
import CountryAutocomplete from '@/modules/country/components/CountryAutocomplete.vue';
import StoreAutocomplete from '@/modules/store/components/StoreAutocomplete.vue';
import { getTranslatedEnumOptions } from '@/application/helpers/enum.js';
import UsersAutocomplete from '@/modules/user/components/UsersAutocomplete.vue';
import {mapGetters} from 'vuex';

export default {
  name: 'OrdersFilter',
  inheritAttrs: false,
  components: {
    UsersAutocomplete,
    StoreAutocomplete,
    CountryAutocomplete,
    KCheckboxGroup,
    KSidebar,
    KFieldGroup,
    KDateField,
  },
  props: {
    filters: {
      type: Object,
    },
  },
  data: () => ({
    form: {},
  }),
  watch: {
    filters: {
      handler(values) {
        this.form = { ...this.form, ...values };
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('settings', { clientId: 'getCurrentClientId' }),
    selectableOrderStatus() {
      return getTranslatedEnumOptions(orderStatus, 'orders.orderStatus');
    },
  },
  methods: {
    handleFilterClick() {
      this.cleanFilters(this.form);
      this.$emit('update:filters', this.form);
      this.$emit('input', false);
    },
    cleanFilters(obj) {
      for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
};
</script>
