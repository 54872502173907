<template>
  <svg width="24" viewBox="0 0 24 24" :fill="backgroundFill" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.172 20.828C15.896 21.552 16.896 22 18 22C20.208 22 22 20.208 22 18H24C24 21.311 21.311 24 18 24C16.344 24 14.844 23.328 13.758 22.242L12 24V19H17L15.172 20.828ZM10.252 20H1C0.448 20 0 19.552 0 19V8H20V10.252C19.361 10.088 18.69 10 18 10C13.585 10 10 13.585 10 18C10 18.69 10.088 19.361 10.252 20ZM22.242 13.758L24 12V17H19L20.828 15.172C20.104 14.448 19.104 14 18 14C15.792 14 14 15.792 14 18H12C12 14.689 14.689 12 18 12C19.656 12 21.156 12.672 22.242 13.758ZM9 6H0L4.5 0H9V6ZM20 6H11V0H15.5L20 6Z"
          :fill="fill"/>
  </svg>
</template>

<script>
export default {
  name: 'IconPendingApproval',
  props: {
    backgroundFill: {
      type: String,
      default: 'transparent',
    },
    fill: {
      type: String,
      default: '#333333',
    },
  },
};
</script>
