import { get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

export const index = (clientId, page, perPage, search, sortBy, descending, params) =>
  getPaginated(`client/${clientId}/user`, page, perPage, search, sortBy, descending, params);

export const autocomplete = (clientId, page, perPage, search, sortBy, descending, params) =>
  getPaginated(`client/${clientId}/user`, page, perPage, search, sortBy, descending, params);

export const show = (id) => get(`user/${id}`);

export const create = (clientId, values) => post(`client/${clientId}/user`, values);

export const update = (values) => put(`user/${values.id}`, values);
