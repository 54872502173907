<template>
  <svg width="24" viewBox="0 0 24 24" :fill="backgroundFill" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.013 14.0096V11.0075H21.0144V14.0096H22.0151C22.5675 14.0096 23.0158 14.4579 23.0158 15.0103V18.0144C23.0158 18.0144 23.34 18.0614 23.4641 18.1264C23.7743 18.2885 23.9804 18.6098 23.9995 18.958C24.0055 19.0751 23.9594 19.2992 23.9594 19.2992L21.894 23.367C21.894 23.367 21.82 23.5301 21.7669 23.6042C21.6348 23.7863 21.4407 23.9204 21.2236 23.9805C21.1575 23.9985 21.0244 24.0145 21.0244 24.0145L11.9512 24.0165C11.9512 24.0165 11.7731 24.0045 11.685 23.9805C11.4679 23.9204 11.2737 23.7863 11.1416 23.6042C11.1016 23.5481 11.0396 23.4291 11.0396 23.4291L9.07022 19.3643C9.07022 19.3643 9.00017 19.0991 9.00718 18.958C9.02619 18.6098 9.23333 18.2885 9.54254 18.1264C9.66663 18.0614 9.93782 18.0144 9.93782 18.0144L11.0075 18.0124V16.011H14.9533V18.0144H16.011V15.0103C16.011 14.4579 16.4593 14.0096 17.0117 14.0096H19.013ZM21.5568 20.0137H11.4509L12.5086 22.0151H20.5141L21.5568 20.0137ZM7.05484 20.0137H1.00069C0.448307 20.0137 0 19.5654 0 19.013V8.00549H20.0137V9.46549C19.0661 9.16729 18.0574 9.00618 17.0117 9.00618C11.4889 9.00618 7.0048 13.4903 7.0048 19.013C7.0048 19.3503 7.02181 19.6845 7.05484 20.0137ZM21.0144 18.0144V16.011H18.0124V18.0144H21.0144ZM9.00618 6.00412H0L4.50309 0H9.00618V6.00412ZM20.0137 6.00412H11.0075V0H15.5106L20.0137 6.00412Z"
          :fill="fill"/>
  </svg>
</template>

<script>
export default {
  name: 'IconShipped',
  props: {
    backgroundFill: {
      type: String,
      default: 'transparent',
    },
    fill: {
      type: String,
      default: '#333333',
    },
  },
};
</script>
