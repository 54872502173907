<template>
  <svg width="24" viewBox="0 0 24 24" :fill="backgroundFill" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.5 16H24L20.5 19L22 24L18 21L14 24L15.5 19L12 16H16.5L18 11.5L19.5 16ZM10.252 20H1C0.448 20 0 19.552 0 19V8H20V10.252C19.361 10.088 18.69 10 18 10C13.585 10 10 13.585 10 18C10 18.69 10.088 19.361 10.252 20ZM9 6H0L4.5 0H9V6ZM20 6H11V0H15.5L20 6Z"
          :fill="fill"/>
  </svg>
</template>

<script>
export default {
  name: 'IconNew',
  props: {
    backgroundFill: {
      type: String,
      default: 'transparent',
    },
    fill: {
      type: String,
      default: '#333333',
    },
  },
};
</script>
