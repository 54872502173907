<template>
  <div :class="this.grid" class="k-field-wrapper">
    <div :class="checkboxGroupClass" class="pt-3">
      <v-label v-if="translatedLabel">{{ translatedLabel }}</v-label>
      <v-checkbox
          v-for="(checkbox, index) in items"
          :key="index"
          v-bind="fieldProps"
          :class="checkboxClass"
          v-model="checked"
          :rules="computedRules"
          :label="checkbox.text"
          :value="checkbox.value"
          class="mt-1"/>
    </div>
  </div>
</template>

<script>
import Field from '@/components/crud/fields/FieldMixin.vue';

export default {
  name: 'KCheckboxGroup',
  inheritAttrs: false,
  mixins: [Field],
  data: () => ({ checked: [] }),
  props: {
    value: { type: [Array, Number] },
    checkboxGroupClass: { type: String },
    checkboxClass: { type: String },
    items: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    checked(values) {
      this.$emit('input', values);
    },
  },
  created() {
    if (this.value) this.checked = Array.isArray(this.value) ? this.value : [this.value];
  },
};
</script>
