import Model from './model';

export class Language extends Model {
  id = 0;
  locale = '';
  currency = '';
  name = '';

  mapForRequest() {
    return this;
  }
}
