var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('k-crud-layout',{attrs:{"search":_vm.searchQuery,"filters":_vm.filters},on:{"update:search":function($event){_vm.searchQuery=$event},"update:filters":function($event){_vm.filters=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(_vm._s(_vm.title))]},proxy:true},{key:"flow-actions",fn:function(){return [_c('ExportOrdersButton')]},proxy:true},{key:"filters",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('OrdersFilter',_vm._g(_vm._b({},'OrdersFilter',attrs,false),on))]}},{key:"view.list",fn:function(){return [_c('k-crud-table',{ref:"table",attrs:{"headers":_vm.crudHeaders,"index-request":_vm.indexRequest,"search":_vm.searchQuery,"params":_vm.filters,"language-prefix":"orders.crudHeaders","resource":"orders"},scopedSlots:_vm._u([{key:"item.statusIcon",fn:function(ref){
var orderStatusId = ref.item.orderStatusId;
return [_c('v-row',{staticClass:"justify-center align-center"},[_c('v-col',{staticStyle:{"height":"48px"},attrs:{"cols":"auto"}},[_c('OrderStatusIcon',{attrs:{"orderStatusId":orderStatusId}})],1)],1)]}},{key:"item.orderStatusId",fn:function(ref){
var orderStatusId = ref.item.orderStatusId;
return [_c('span',[_vm._v(_vm._s(_vm.$t(("orders.orderStatus." + (_vm.getOrderStatus(orderStatusId))))))])]}},{key:"item.date",fn:function(ref){
var date = ref.item.date;
return [_c('span',[_vm._v(_vm._s(_vm.dayjs(date, 'YYYY-MM-DD HH:mm:ss').format('LL')))])]}},(_vm.detailTo)?{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","to":{name: _vm.detailTo, params: {orderId: item.id}}}},[_c('v-icon',[_vm._v("$visible")])],1)]}}:null],null,true)})]},proxy:true}])},[_vm._v("> ")])}
var staticRenderFns = []

export { render, staticRenderFns }