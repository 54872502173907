import Vue from 'vue';
import { IconApproved, IconInProduction, IconNew, IconPendingApproval, IconShipped } from '@/modules/orders/assets/svg';

export default Vue.component('order-status-icon', {
  functional: true,
  props: {
    orderStatusId: {
      type: Number,
      required: true,
    },
  },
  render(createElement, context) {
    const orderStatusIcon = (id) => {
      switch (id) {
        case 1:
          return IconNew;
        case 2:
          return IconPendingApproval;
        case 3:
          return IconApproved;
        case 4:
          return IconInProduction;
        case 5:
          return IconShipped;
        default:
          return IconNew;
      }
    }
    return createElement(
      orderStatusIcon(context.props.orderStatusId)
    )
  }
})
