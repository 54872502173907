<template>
  <KPaginatedAutocomplete v-bind="$attrs" v-on="$listeners" :paginator="autocomplete"/>
</template>

<script>
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete.vue';
import { autocomplete } from '@/modules/country/api';

export default {
  name: 'CountryAutocomplete',
  components: { KPaginatedAutocomplete },
  methods: {
    autocomplete
  },
};
</script>
