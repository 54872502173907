<template>
  <v-dialog width="600" v-model="dialog">
    <template #activator="{on}">
      <v-btn tile outlined v-on="on">{{ $t('orders.actions.export') }}</v-btn>
    </template>
    <v-card :loading="loading">
      <v-card-actions>
        <VSpacer/>
        <v-btn tile outlined @click="exportOrders" :loading="loading">{{ $t('orders.actions.export') }}</v-btn>
      </v-card-actions>
      <v-card-title>
        <h3 class="text-h3 secondary--text text-uppercase">{{ $t('orders.actions.export') }}</h3>
      </v-card-title>
      <v-card-text>
        <k-field-group language-prefix="orders.export.fields">
          <KDateField field="startDate" v-model="startDate"/>
          <KDateField field="endDate" v-model="endDate"/>
        </k-field-group>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KDateField from '@/components/crud/fields/KDateField.vue';
import { exportOrders } from '@/modules/orders/api';
import downloadFile from '@/application/util/downloadFile.js';

export default {
  name: 'ExportOrdersButton',
  components: {
    KDateField,
    KFieldGroup,
  },
  data: () => ({
    startDate: '',
    endDate: '',
    dialog: false,
    loading: false,
  }),
  methods: {
    async exportOrders() {
      this.loading = true;
      const file = await exportOrders({
        startDate: this.startDate,
        endDate: this.endDate,
      });
      await downloadFile(file, 'export.csv');
      this.dialog = false;
      this.loading = false;
    },
  },
};
</script>
